<template>
	<div id="join">
		<banner :picS="bannerS" />
		<div class="joinBox">
			<titleOne id="join_1" info="PHROMON男性向けプライベート健康ケア" en='Join Advantage' title="加盟のメリット"></titleOne>
		</div>
		<div class="joinList">
			<ul class="widthBox acea-row">
				<li v-for="item in joinList">
					<img :src="item.img" alt="" class="pic">
					<h4>{{item.title}}</h4>
					<div class="txt">
						<p v-for="i in item.content">{{i}}</p>
					</div>
				</li>
			</ul>
		</div>
		<div class="process">
			<titleOne id="join_2" en='Joining process' title="加盟の流れ"></titleOne>
			<div class="box widthBox">
				<img :src="joining.img" alt="" class="pic">
				<!-- <ul class="acea-row row-between">
					<li>
						<span class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
						<p>前期咨询<br/>洽谈、考察</p>
					</li>
					<li>
						<span class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
						<p>双方签约<br/>购买加盟套餐</p>
					</li>
					<li>
						<span class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
						<p>提供全面培训<br/>指导销售服务</p>
					</li>
					<li>
						<span class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
						<p>一键分享<br/>开启您的财富事业</p>
					</li>
					<li>
						<span class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
						<p>专属客服<br/>一对一快速解答</p>
					</li>
				</ul>
				<img src="@/assets/bg_05.png" alt="" class="pic">
				<div class="hot acea-row row-between">
					<span class="active">了解项目</span>
					<span>签署协议</span>
					<span>运营指导</span>
					<span>正式启动</span>
					<span>售后服务</span>
				</div> -->
			</div>
		</div>
		<div class="our">
			<titleOne id="join_3" en='Our Cooperation ' title="加盟したい方"></titleOne>
			<div class="cont">
				<div class="widthBox acea-row row-between">
					<div class="form">
						<label class="formItem acea-row row-middle">
							<span class="iconfont icon-jurassic_user"></span>
							<input placeholder="お名前" v-model="formData.name" type="text">
						</label>
						<label class="formItem acea-row row-middle">
							<span class="iconfont icon-dianhuatianchong"></span>
							<input placeholder="電話番号" v-model="formData.mobile" type="text">
						</label>
						<label class="formItem acea-row row-middle">
							<span class="iconfont icon-ditu-dibiao"></span>
							<input placeholder="都道府県名" v-model="formData.address" type="text">
						</label>
						<button @click="joinSubmit">送&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;信</button>
					</div>
					<div class="right">
						<p>
							{{joinsetting.text1}}</br>
							<span>{{joinsetting.text2}}</span>
						</p>
						<p>
							ビジネスホットライン：</br>
							<span>{{joinsetting.merchants_number}}</span>
						</p>
						<p>
							{{joinsetting.text3}}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import banner from '@/components/banner.vue'
	import titleOne from '@/components/titleOne.vue'
	export default {
		name: 'case',
		components:{
			banner,titleOne
		},
		computed: {
			bannerS() {
				return this.$store.state.bannerS
			},
			common() {
				return this.$store.state.common
			},
		},
		data:function() {
			return {
				joinList:'',
				joining:[],
				joinsetting:{},
				formData:{
					name:'',
					mobile:'',
					address:''
				}
			};
		},
		created() {
			this.$api.get('home/index/joinAdvantage',{}).then(res=>{
				this.joinList = res.data.list
			})
			this.$api.get('home/index/joiningProcess',{}).then(res=>{
				this.joining = res.data.list
			})
			this.$api.get('home/index/joinsetting',{}).then(res=>{
				this.joinsetting = res.data
			})
		},
		mounted() {
			this.$nextTick(function(){
				if(this.$route.query.to != undefined || this.$route.query.to != null){
					setTimeout(()=>{
						document.getElementById(this.$route.query.to).scrollIntoView()
					},500)
				}else{
					document.body.scrollIntoView()
				}
			})
		},
		methods:{
			joinSubmit(){
				let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
				if(!this.formData.name||!this.formData.mobile||!this.formData.address){
					this.$message.error('内容を完璧にしてください');
					return
				}
				this.$confirm('メッセージの提出を確認しますか?', 'ヒント', {
				  confirmButtonText: '確定した',
				  cancelButtonText: 'キャンセル',
				  type: 'info'
				}).then(() => {
				  this.$api.post('/home/submits/joinMessage',this.formData).then(res=>{
					  this.$message({
						message:res.msg,
						type: 'success'
					  });
					  this.formData.name = ''
					  this.formData.mobile = ''
					  this.formData.address = ''
				  })
				})
			}
		}
	}
</script>

<style lang="scss">
	#join{
		.joinBox{
			padding:60px 0;
		}
		.joinList{
			background:rgba($color: #1D2A55, $alpha: 0.05);
			padding:80px 0 30px;
			li{
				width:calc(100% / 3 - 30px);
				background:#FFF;
				box-shadow: 0px 5px 19px 1px rgba(29, 42, 85, 0.2);
				margin-right:45px;
				padding-bottom:30px;
				margin-bottom:50px;
				text-align: center;
				&:nth-child(3n){
					margin-right:0;
				}
				.pic{
					width:100%;
				}
				h4{
					font-size:32px;
					padding:30px 0;
					margin:0 30px;
					// overflow: hidden;
					// white-space: nowrap;
					// text-overflow: ellipsis;
					color:#1D2A55;
					position: relative;
					&::after{
						content:'';display: block;width:80px;height:4px;
						background:#1D2A55;
						position: absolute;
						bottom:0;
						left:50%;
						transform: translate(-50%,0);
					}
				}
				.txt{
					margin-top:30px;
					padding:0 30px;
					p{
						color:rgba($color: #1D2A55, $alpha: 0.5);
						font-size:24px;
					}
				}
			}
		}
		.process{
			padding:80px 0 0 0;
			.box{
				padding:60px 0;
				ul{
					margin-bottom:50px;
					li{
						background:#FFF;
						width:calc(100% / 5 - 20px);
						box-shadow: 0px 5px 19px 1px rgba(29, 42, 85, 0.2);
						text-align: center;
						padding:50px 0 30px;
						height:300px;
						box-sizing: border-box;
						position: relative;
						&:nth-child(2n){
							transform: translate(0,90px);
						}
						&::after{
							content:'';
							position: absolute;
							z-index: 1;
							left:50%;
							bottom:-20px;
							transform: translate(-50%,0);
							border-top:10px solid #FFF;
							border-left:10px solid transparent;
							border-right:10px solid transparent;
							border-bottom:10px solid transparent;
						}
						span{
							width:80px;
							height:80px;
							border-radius:50%;
							background:#1D2A55;
							color:#FFF;
							margin:0 auto;
							font-size:36px;
						}
						p{
							margin-top:30px;
							font-size:20px;
							line-height:36px;
						}
					}
				}
				.pic{
					width:100%;
				}
				.hot{
					padding:50px 0;
					span{
						width:calc(100% / 5 - 40px);
						background:#F3F4F6;
						color:#1D2A55;
						font-size:24px;
						margin-right:20px;
						line-height:60px;
						display: block;
						text-align: center;
						font-weight: bold;
						position: relative;	
						&::after{
							content:'';
							position: absolute;
							z-index: 1;
							right:-25px;
							top:50%;
							transform: translate(0,-50%);
							border-top:30px solid transparent;
							border-left:15px solid #F3F4F6;
							border-right:10px solid transparent;
							border-bottom:30px solid transparent;
						}
						&.active{
							background:#1D2A55;
							color:#FFF;
							&::after{
								border-left:15px solid #1D2A55;
							}
						}
						
					}
				}
			}
		}
		.our{
			padding-top:80px;
			border-top:1px solid rgba($color: #1D2A55, $alpha: 0.5);
			.cont{
				margin-top:50px;
				background:rgba($color: #1D2A55, $alpha: 0.05);
				padding:80px 0;
				.form{
					display: block;
					width:45%;
					.formItem{
						margin-top:40px;
						padding:0px 30px;
						height:64px;
						box-sizing: border-box;
						border:1px solid rgba($color: #1D2A55, $alpha: 0.6);
						&:first-child{
							margin-top:0;
						}
					}
					span{
						font-size:36px;
						vertical-align: middle;
						width:36px;
						margin-right:14px;
						display: inline-block;
						color:rgba($color: #1D2A55, $alpha: 0.6);
					}
					.icon{
						width:36px;
						max-height:36px;
						margin-right:14px;
						vertical-align: middle;
					}
					input{
						width:calc(100% - 60px);
						border:0;background:none;
						font-size:18px;
						outline: 0;
						height:36px;
						color:rgba($color: #1D2A55, $alpha: 0.6);
						vertical-align: middle;
					}
					button{
						width: 240px;
						height: 64px;
						background: #1D2A55;
						text-align: center;
						color:#FFF;
						font-size:20px;
						margin-top:40px;
					}
				}
				.right{
					width:48%;
					p{
						font-size:20px;
						line-height:36px;
						color:#1D2A55;
						margin-top:40px;
						&:first-child{
							margin-top:0;
						}
						span{
							font-size:24px;
						}
					}
				}
			}
		}
	}
	
	@media screen and(max-width:768px) {
		.widthBox{
			padding: 0 0px;
		}
		#join{
			.joinBox{
				padding:20px 0 30px;
				#join_1{
					height: 70px;
				}
			}
			.joinList{
				padding: 20px 0px;
				margin: 0 16px;
				background: #fff;
				border-bottom: 1px solid #E6E6E6;
				li{
					width:calc(50% - 5px);
					box-shadow: none;
					background: #F5F5F5;
					margin-right:10px;
					padding-bottom:10px;
					margin-bottom:20px;
					&:nth-child(3n) {
					    margin-right: auto;
					}
					&:nth-child(even){
						margin-right:0;
					}
					
					h4{
						font-size:14px;
						padding:8px 0;
						margin:0 10px;
						line-height:20px;
						color: #272E4D;
						&::after {
							width: 40px;
							height: 2px;
						}
					}
					.txt{
						margin-top:8px;
						padding:0 10px;
						p{
							font-size:14px;
							color: #3E404D;
						}
					}
				}
			}
			
			.process{
				padding:20px 0 0 0;
			}
		}
		.process{
				margin: 0 16px;
			.widthBox{
			padding: 20px 0px !important;
			}
		}
		.our{
			margin: 0 16px;
			border-top: 1px solid #E6E6E6 !important;
			border-bottom: 1px solid #E6E6E6 !important;
			padding: 32px 0 !important;
			.cont{
				padding: 0px !important;
				background: #fff !important;
				margin: 0px 12px !important;
				margin-top: 20px !important;
				.widthBox{
					.form{
						width: 100% !important;
						.formItem{
							padding: 0 16px !important;
							height: 40px !important;
							border: none !important;
							background: #F5F5F5 !important;
							display: flex;
							input {
								flex: 1;
								width: auto;
							}
						}
						button{
							width: 100% !important;
							height: 40px !important;
							line-height: 40px !important;
							border: none !important;
							font-size: 14px !important;
						}
					}
					.right{
						width: 100% !important;
						// margin-top: 24px;
						P{
							font-size: 16px !important;
							color: #272E4D !important;
							line-height: 18px !important;
							margin-top: 16px !important;
							span{
								font-size: 14px !important;
								color: #3E404D !important;
							}
						}
					}
				}
			}
		}
	}
</style>
